<template>
  <div class="components-upload">
    <el-upload
      v-if="fileType == 'single'"
      class="avatar-uploader"
      :action="BaseUrl + uploadUrl"
      :headers="{ Authorization: getAccessToken() }"
      :show-file-list="false"
      :data="uploadData"
      :on-success="handleSuccess"
      :before-remove="beforeRemove"
      :before-upload="beforeUpload"
      :on-remove="handleRemove"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <el-upload
      v-if="fileType == 'multiple'"
      list-type="picture-card"
      :action="BaseUrl + uploadUrl"
      :headers="{ Authorization: getAccessToken() }"
      :limit="limit"
      :data="uploadData"
      :on-success="handleSuccess"
      :before-remove="beforeRemove"
      :before-upload="beforeUpload"
      :on-remove="handleRemove"
    >
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-upload
      v-if="fileType == 'file'"
      :action="BaseUrl + uploadUrl"
      :headers="{ Authorization: getAccessToken() }"
      :limit="limit"
      :data="uploadData"
      :on-success="handleSuccess"
      :before-remove="beforeRemove"
      :before-upload="beforeUpload"
      :on-remove="handleRemove"
      :file-list="fileList"
      :show-file-list="showList"
    >
      <el-button type="success" size="small">{{ buttonTxt }}</el-button>
      <div slot="tip" class="el-upload__tip">{{ tips }}</div>
    </el-upload>

    <el-upload class="avatar-uploader"
      v-if="fileType == 'video'"
      :action="BaseUrl+uploadUrl"
      :data="uploadDataB"
      :on-progress="uploadVideoProcess"
      :on-success="handleVideoSuccess"
      :before-upload="beforeUploadVideo"
      :show-file-list="false">
    <video v-if="videoForm.showVideoPath !='' && !videoFlag"
      :src="videoForm.showVideoPath"
      class="avatar video-avatar"
      controls="controls">
    您的浏览器不支持视频播放
    </video>
    <i v-else-if="videoForm.showVideoPath =='' && !videoFlag"
    class="el-icon-plus avatar-uploader-icon"></i>
    <el-progress v-if="videoFlag == true"
      type="circle"
      :percentage="videoUploadPercent"
      style="margin-top:7px;"></el-progress>
    </el-upload>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { log, getAccessToken } from "@/common/utils";
import { BaseUrl } from "@/common/config";
import fileUpload from "@/mixins/fileUpload";
export default {
  name: "upload",
  mixins: [fileUpload],
  props: {
    limit: {
      type: Number,
      default: 5,
    },
    fileType: {
      type: String,
      default: "multiple",
    },
    value: "",
    tips: {
      type: String,
      default: "只能上传excel/word/ppt文件",
    },
    buttonTxt: {
      type: String,
      default: "点击上传",
    },
    showList: {
      type: Boolean,
      default: true,
    },
    uploadData: {
      type: Object,
      default: null,
    },
    uploadDataB: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      BaseUrl,
      imageUrl: "",
      videoFlag: false,
      //是否显示进度条
      videoUploadPercent: "",
      //进度条的进度，
      isShowUploadVideo: false,
      //显示上传按钮
      videoForm: {
          showVideoPath: ''
      }
    };
  },
  watch: {
    value(n, o) {
      this.onValueChange(n, o);
    },
  },
  methods: {
    getAccessToken,

    onValueChange(val, o) {
      if(val){
        if(this.fileType == 'file'){
          let arr = []
          if(this.limit == 1 && val){
            arr.push({
              name: val,
              path: val
            })
          }
          if(this.limit > 1 && val.length){
            this.list = val
            val.forEach(k=>{
              arr.push({
                name: k.url,
                path: k.url
              })
            })
          }
          this.fileList = arr
        }
      }else{
        this.fileList = []
        this.list = []
      }
      if(this.fileType == 'single'){
        this.imageUrl = val
      }
    },
    beforeUpload(file) {
      const promise = new Promise((resolve, reject) => {
        this.$emit("beforeUpload", file, (val) => {
          val ? resolve(val) : reject(val);
        });
      });
      return promise;
    },
    /** 上传前回调 */
    beforeUploadVideo(file) {
        var fileSize = file.size / 1024 / 1024 < 500;
        if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) == -1) {
            this.$message.error("请上传正确的视频格式");
            return false;
        }
        if (!fileSize) {
            this.$message.error("视频大小不能超过500MB");
            return false;
        }
        this.isShowUploadVideo = false;
    },
    //进度条
    uploadVideoProcess(event, file, fileList) {
        this.videoFlag = true;
        this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },
    //上传成功回调
    handleVideoSuccess(res, file) {
        this.isShowUploadVideo = true;
        this.videoFlag = false;
        this.videoUploadPercent = 0;
        
        //前台上传地址
        //if (file.status == 'success' ) {
        //    this.videoForm.showVideoPath = file.url;
        //} else {
        //     this.$message.error("上传失败，请重新上传");
        //}

        //后台上传地址
        if (res.Code == 0) {
            this.videoForm.showVideoPath = res.Data;
        } else {
            this.$message.error(res.Message);
        }
    }
  },
  mounted(){
    if (this.fileType == "single") {
      this.imageUrl = this.value;
    }
  }
};
</script>
<style lang="scss">
.components-upload {
  .avatar-uploader .el-upload {
    border: 1px dashed #c0ccda;
    background: #fbfdff;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
  }
  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }
}
</style>